div.cbb-filter-input-wrapper {
  display: flex;
  justify-content: center;
}
div.cbb-filter-input-wrapper span.cbb-table-text-filter {
  display: flex;
  align-items: baseline;
  overflow: hidden;
  flex-grow: 1;
  margin: 0 1px;
  height: 19px;
  background: white;
  border: 1px solid #222;
  border-radius: 3px;
}
div.cbb-filter-input-wrapper span.cbb-table-text-filter input {
  border: 0;
  padding-left: 3px;
  padding-right: 2px;
}
div.cbb-filter-input-wrapper span.cbb-table-filter {
  display: flex;
  align-items: center;
  background: white;
  color: #222222;
  border-radius: 3px;
  border: 1px solid #222222;
  padding: 2px;
  font-size: 0.66em;
  max-width: 35px;
  overflow: auto;
  margin-left: 1px;
}
div.cbb-filter-input-wrapper span.cbb-table-filter input {
  text-align: right;
  max-width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  padding-right: 3px;
  text-indent: 1px;
  outline: none;
  -moz-appearance: textfield;
}
div.cbb-filter-input-wrapper span.cbb-table-filter input::placeholder {
  text-align: left;
}
div.cbb-filter-input-wrapper span.show-pct:after {
  content: "%";
  margin-left: -6px;
  color: #222222;
  font-size: 0.9em;
}
div.cbb-filter-input-wrapper span.show-x:after {
  content: "x";
  margin-left: -6px;
  color: #222222;
  font-size: 0.9em;
}

.scroll-table-wrapper, .expand-table-wrapper {
  position: relative;
  max-width: 100%;
}
.scroll-table-wrapper .cbb-table, .expand-table-wrapper .cbb-table {
  font-family: "helvetica";
  letter-spacing: 0em;
  overflow: overlay;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n), .scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-1), .scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-2), .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n), .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-1), .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-2) {
  background-color: #F2F2F2;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n) [data-sticky-td], .scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-1) [data-sticky-td], .scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-2) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-1) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-2) [data-sticky-td] {
  background-color: #F2F2F2;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-3), .scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-4), .scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-5), .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-3), .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-4), .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-5) {
  background-color: #FFFFFF;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-3) [data-sticky-td], .scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-4) [data-sticky-td], .scroll-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-5) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-3) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-4) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow3:nth-child(6n-5) [data-sticky-td] {
  background-color: #FFFFFF;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n), .scroll-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-1), .expand-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n), .expand-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-1) {
  background-color: #F2F2F2;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n) [data-sticky-td], .scroll-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-1) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-1) [data-sticky-td] {
  background-color: #F2F2F2;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-2), .scroll-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-3), .expand-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-2), .expand-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-3) {
  background-color: #FFFFFF;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-2) [data-sticky-td], .scroll-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-3) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-2) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow2:nth-child(4n-3) [data-sticky-td] {
  background-color: #FFFFFF;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(even), .expand-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(even) {
  background-color: #F2F2F2;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(even) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(even) [data-sticky-td] {
  background-color: #F2F2F2;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(even).pinned-row.pin-top, .expand-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(even).pinned-row.pin-top {
  border-bottom: 1px solid #999;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(even).pinned-row.pin-bottom, .expand-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(even).pinned-row.pin-bottom {
  border-top: 1px solid #999;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(even).pinned-row .td, .expand-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(even).pinned-row .td {
  background-color: #CCC;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(odd), .expand-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(odd) {
  background-color: #FFFFFF;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(odd) [data-sticky-td], .expand-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(odd) [data-sticky-td] {
  background-color: #FFFFFF;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(odd).pinned-row.pin-top, .expand-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(odd).pinned-row.pin-top {
  border-bottom: 1px solid #999;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(odd).pinned-row.pin-bottom, .expand-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(odd).pinned-row.pin-bottom {
  border-top: 1px solid #999;
}
.scroll-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(odd).pinned-row .td, .expand-table-wrapper .cbb-table .table-body .tr.nthRow1:nth-child(odd).pinned-row .td {
  background-color: #CCC;
}
.scroll-table-wrapper .cbb-table .table-body .tr:hover, .expand-table-wrapper .cbb-table .table-body .tr:hover {
  background-color: rgba(0, 102, 204, 0.24);
}
.scroll-table-wrapper .cbb-table .table-body .tr:last-child, .expand-table-wrapper .cbb-table .table-body .tr:last-child {
  border-bottom: 1px solid #333333;
}
.scroll-table-wrapper .cbb-table .table-headers .tr, .expand-table-wrapper .cbb-table .table-headers .tr {
  font-weight: 700;
  background-color: #F2F2F2;
}
.scroll-table-wrapper .cbb-table .table-headers .tr:first-child, .expand-table-wrapper .cbb-table .table-headers .tr:first-child {
  border-top: 1px solid #333333;
}
.scroll-table-wrapper .cbb-table .table-headers .tr:last-child, .expand-table-wrapper .cbb-table .table-headers .tr:last-child {
  border-bottom: 1px solid #333333;
}
.scroll-table-wrapper .cbb-table .table-headers .tr [data-sticky-td], .expand-table-wrapper .cbb-table .table-headers .tr [data-sticky-td] {
  background-color: #F2F2F2;
}
.scroll-table-wrapper .cbb-table .th, .expand-table-wrapper .cbb-table .th {
  font-size: 14px;
  padding: 1px;
  text-align: center;
  height: auto;
}
.scroll-table-wrapper .cbb-table .th.hide-filters .cbb-filter-input-wrapper, .expand-table-wrapper .cbb-table .th.hide-filters .cbb-filter-input-wrapper {
  display: none;
}
.scroll-table-wrapper .cbb-table .th .sorter, .expand-table-wrapper .cbb-table .th .sorter {
  border-radius: 5px;
}
.scroll-table-wrapper .cbb-table .th .sorter:hover, .expand-table-wrapper .cbb-table .th .sorter:hover {
  background-color: rgba(0, 102, 204, 0.2);
}
.scroll-table-wrapper .cbb-table .th .col-sort-desc, .expand-table-wrapper .cbb-table .th .col-sort-desc {
  border-radius: 0;
  box-shadow: inset 0 -3px 0 0 #0066CC;
}
.scroll-table-wrapper .cbb-table .th .col-sort-asc, .expand-table-wrapper .cbb-table .th .col-sort-asc {
  border-radius: 0;
  box-shadow: inset 0 3px 0 0 #0066CC;
}
.scroll-table-wrapper .cbb-table .th.text-left, .expand-table-wrapper .cbb-table .th.text-left {
  text-align: left;
  padding-left: 5px;
}
.scroll-table-wrapper .cbb-table .th.child-margin-left-18 div, .expand-table-wrapper .cbb-table .th.child-margin-left-18 div {
  margin-left: 18px;
}
@media print {
  .scroll-table-wrapper .cbb-table .th.child-margin-left-18 div, .expand-table-wrapper .cbb-table .th.child-margin-left-18 div {
    margin-left: 0px;
  }
}
.scroll-table-wrapper .cbb-table .th.child-margin-left-38 div, .expand-table-wrapper .cbb-table .th.child-margin-left-38 div {
  margin-left: 38px;
}
.scroll-table-wrapper .cbb-table .th.pad-left-10, .expand-table-wrapper .cbb-table .th.pad-left-10 {
  padding-left: 10px;
}
.scroll-table-wrapper .cbb-table .th.pad-right-10, .expand-table-wrapper .cbb-table .th.pad-right-10 {
  padding-right: 10px;
}
.scroll-table-wrapper .cbb-table .th.pad-left-15, .expand-table-wrapper .cbb-table .th.pad-left-15 {
  padding-left: 15px;
}
.scroll-table-wrapper .cbb-table .th.pad-right-15, .expand-table-wrapper .cbb-table .th.pad-right-15 {
  padding-right: 15px;
}
.scroll-table-wrapper .cbb-table .th.pad-left-20, .expand-table-wrapper .cbb-table .th.pad-left-20 {
  padding-left: 20px;
}
.scroll-table-wrapper .cbb-table .th.pad-right-20, .expand-table-wrapper .cbb-table .th.pad-right-20 {
  padding-right: 20px;
}
.scroll-table-wrapper .cbb-table .th.left, .expand-table-wrapper .cbb-table .th.left {
  text-align: left;
  padding-left: 5px;
}
.scroll-table-wrapper .cbb-table .td, .expand-table-wrapper .cbb-table .td {
  font-size: 13px;
  padding: 0px;
  margin: 0px;
  height: auto;
  line-height: 28px;
  max-height: 28px;
}
.scroll-table-wrapper .cbb-table .td:last-child, .expand-table-wrapper .cbb-table .td:last-child {
  border-right: none;
}
.scroll-table-wrapper .cbb-table .td.pad-left-10, .expand-table-wrapper .cbb-table .td.pad-left-10 {
  padding-left: 10px;
}
.scroll-table-wrapper .cbb-table .td.pad-right-10, .expand-table-wrapper .cbb-table .td.pad-right-10 {
  padding-right: 10px;
}
.scroll-table-wrapper .cbb-table .td.pad-left-15, .expand-table-wrapper .cbb-table .td.pad-left-15 {
  padding-left: 15px;
}
.scroll-table-wrapper .cbb-table .td.pad-right-15, .expand-table-wrapper .cbb-table .td.pad-right-15 {
  padding-right: 15px;
}
.scroll-table-wrapper .cbb-table .td .text-span, .expand-table-wrapper .cbb-table .td .text-span {
  display: flex;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  color: #333333;
  width: 100%;
}
.scroll-table-wrapper .cbb-table .td .text-span.left, .expand-table-wrapper .cbb-table .td .text-span.left {
  justify-content: flex-start;
  padding-left: 4px;
}
.scroll-table-wrapper .cbb-table .td .text-span a, .expand-table-wrapper .cbb-table .td .text-span a {
  text-decoration: underline;
  color: #333;
}
.scroll-table-wrapper .cbb-table .td .text-span a:hover, .expand-table-wrapper .cbb-table .td .text-span a:hover {
  color: #0066CC;
}
.scroll-table-wrapper .cbb-table .td .text-span.short-name, .expand-table-wrapper .cbb-table .td .text-span.short-name {
  width: auto;
  padding-right: 4px;
  padding-left: 2px;
}
.scroll-table-wrapper .cbb-table .lineup-names, .expand-table-wrapper .cbb-table .lineup-names {
  font-size: 12px;
  letter-spacing: -0.06em;
}
.scroll-table-wrapper .cbb-table.sticky, .expand-table-wrapper .cbb-table.sticky {
  overflow: auto;
  max-width: 100%;
}
.scroll-table-wrapper .cbb-table.sticky .header, .scroll-table-wrapper .cbb-table.sticky .footer, .expand-table-wrapper .cbb-table.sticky .header, .expand-table-wrapper .cbb-table.sticky .footer {
  position: sticky;
  z-index: 1;
  width: fit-content;
}
.scroll-table-wrapper .cbb-table.sticky .table-body, .expand-table-wrapper .cbb-table.sticky .table-body {
  position: relative;
  z-index: 0;
}
.scroll-table-wrapper .cbb-table.sticky [data-sticky-td], .expand-table-wrapper .cbb-table.sticky [data-sticky-td] {
  position: sticky;
}
.scroll-table-wrapper .cbb-table.sticky [data-sticky-last-left-td], .expand-table-wrapper .cbb-table.sticky [data-sticky-last-left-td] {
  border-right: 1px solid #DDDDDD;
}
.scroll-table-wrapper .cbb-table .in-table-button, .expand-table-wrapper .cbb-table .in-table-button {
  padding: 0;
  display: flex;
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 20;
}

.expand-table-wrapper {
  width: 100%;
}
.expand-table-wrapper table {
  display: inline-flex;
  overflow: initial;
  width: 100%;
  display: table !important;
}
.expand-table-wrapper table .td.left-border {
  border-left: 1px solid #cccccc;
}
.expand-table-wrapper table .td.dist-zone {
  font-size: 10px !important;
}
.expand-table-wrapper table .dist-zone.cbb-filter-input-wrapper.cbb-table-filter {
  width: 28px !important;
}

.full-table {
  display: inline-block;
}

.width-75 {
  width: 75% !important;
}

.scope1 {
  max-width: 240px;
  min-width: 175px;
}

.scope2 {
  max-width: 370px;
  min-width: 253px;
}

.scope3 {
  max-width: 450px;
  min-width: 330px;
}

.scope4 {
  max-width: 540px;
  min-width: 380px;
}

@media only screen and (min-width: 1200px) {
  .scope3 .statValue, .scope4 .statValue {
    flex: 48px 0 auto;
    width: 48px;
  }
  .scope3 .statRating, .scope4 .statRating {
    flex: 35px 0 auto;
    width: 35px;
  }
}
@media only screen and (max-width: 992px) {
  .scope3 .statValue, .scope4 .statValue {
    flex: 40px 0 auto;
    width: 40px;
  }
  .scope3 .statRating, .scope4 .statRating {
    flex: 28px 0 auto;
    width: 28px;
  }
}
@media only screen and (max-width: 768px) {
  .scope3 .statValue, .scope4 .statValue {
    flex: 48px 0 auto;
    width: 48px;
  }
  .scope3 .statRating, .scope4 .statRating {
    flex: 35px 0 auto;
    width: 35px;
  }
}

.scope2TextHeader {
  display: none;
}
@media print {
  .scope2TextHeader {
    display: initial;
  }
}

.sticky-left .left {
  border-right: none !important;
}

.sticky-left.left-last {
  border-right: 1px solid #dddddd;
}

.right-grey {
  border-right: 1px solid #dddddd;
}

.left-grey {
  border-left: 1px solid #dddddd;
}

.player-full-name-width {
  flex: 135px 0 auto;
  width: 135px;
}
@media print {
  .player-full-name-width {
    width: 100px;
  }
}

.cbb-table-resizable .cbb-table .table-headers {
  max-width: 100%;
}
.cbb-table-resizable .cbb-table .table-headers .tr {
  max-width: 100%;
}
.cbb-table-resizable .cbb-table .table-body {
  max-width: 100%;
}
.cbb-table-resizable .cbb-table .table-body .tr {
  max-width: 100%;
}

.pagination-row {
  width: 100%;
}
.pagination-row .pagination-row__panel {
  display: flex;
  float: right;
  align-items: center;
  border-bottom: 1px solid #333333;
  background: #DDDDDD;
  padding: 3px 5px;
}
@media (max-width: 425px) {
  .pagination-row .pagination-row__panel {
    width: 100%;
    justify-content: flex-end;
  }
}
.pagination-row select {
  margin-left: 3px;
  height: 30px;
}
.pagination-row .pagination-row__span1 {
  margin: 0px 4px 0 8px;
}
.pagination-row .pagination-row__span2 {
  margin: 0px 0px 0 4px;
}
.pagination-row .pagination-row__button {
  color: #333333;
  background: white;
  border: 1px solid #333333;
  border-radius: 3px;
  margin-left: 2px;
}

.value-rating-div {
  height: 100%;
}

.value-div {
  font-size: 13px;
  min-width: 40px;
  padding: 0px 4px 0px 0px;
  text-align: right;
  color: #333333;
}

.rating-div {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  font-size: 14px;
  width: 100%;
  color: #333333;
  text-align: center;
  float: right;
}
.rating-div.dnq {
  font-size: 11px;
  opacity: 0.3;
}

div.table-legend {
  position: relative;
  margin-right: 3px;
  margin-top: 1px;
  height: 14px;
  width: 34px;
  border: 1px solid #222222;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.table-legend.pctiles {
  background-image: linear-gradient(to left, #57BB8A, #FFFFFF, #E77C73);
}
div.table-legend.ranks {
  background-image: linear-gradient(to left, #57BB8A, #fff8d7, #E77C73);
}
div.table-legend.big {
  margin: 8px;
  margin-bottom: 0px;
  height: 30px;
  width: 250px;
}
div.table-legend span.legend-title {
  position: absolute;
  left: 254px;
  width: 100%;
  font-weight: 700;
  font-size: 1.1em;
}

div.intable-logo {
  overflow: hidden;
  width: 24px;
  max-height: 24px;
  margin: 2px auto;
  height: 100%;
}
div.intable-logo a.logo {
  height: 100%;
  display: flex;
  align-items: center;
}

div.intable-player-image {
  position: relative;
  border: 1px solid #666;
  border-radius: 50%;
  margin: 1px auto;
  height: 26px;
  overflow: hidden;
  width: 26px;
  display: block;
  cursor: pointer;
}

@media print {
  @page {
    size: auto;
    margin-right: 2.5mm;
  }
  div.table-legend {
    display: none;
  }
  div.intable-logo {
    width: 17px;
    max-height: 17px;
    margin: 1px auto 0px auto;
  }
  div.intable-player-image {
    width: 18px;
    max-height: 18px;
  }
  div.cbb-filter-input-wrapper {
    display: none;
  }
  .pagination-row .pagination-row__panel {
    display: none;
  }
  .scroll-table-wrapper, .expand-table-wrapper {
    width: 100% !important;
    overflow: initial !important;
    padding-right: 15px;
    margin-right: 40px !important;
    display: table !important;
    transform: scaleX(0.96);
    transform-origin: 0 0;
  }
  .scroll-table-wrapper .cbb-table, .expand-table-wrapper .cbb-table {
    display: table !important;
    overflow: initial !important;
    letter-spacing: 0.04em;
  }
  .scroll-table-wrapper .cbb-table .table-body .tr, .expand-table-wrapper .cbb-table .table-body .tr {
    border-bottom: 1px solid #555555;
  }
  .scroll-table-wrapper .cbb-table .th, .expand-table-wrapper .cbb-table .th {
    padding: 1px;
    line-height: 1.2;
    font-size: 12px;
  }
  .scroll-table-wrapper .cbb-table .th.pad-left-10, .expand-table-wrapper .cbb-table .th.pad-left-10 {
    padding-left: 5px;
  }
  .scroll-table-wrapper .cbb-table .td, .expand-table-wrapper .cbb-table .td {
    font-size: 11px;
    line-height: 20px;
  }
  .scroll-table-wrapper .cbb-table .td.row-height-20, .expand-table-wrapper .cbb-table .td.row-height-20 {
    line-height: 20px;
  }
  .scroll-table-wrapper .cbb-table .td.row-height-19, .expand-table-wrapper .cbb-table .td.row-height-19 {
    line-height: 19px;
  }
  .scroll-table-wrapper .cbb-table .td.row-height-18, .expand-table-wrapper .cbb-table .td.row-height-18 {
    line-height: 18px;
  }
  .scroll-table-wrapper .cbb-table .td.row-height-17, .expand-table-wrapper .cbb-table .td.row-height-17 {
    line-height: 17px;
  }
  .scroll-table-wrapper .cbb-table .td.row-height-16, .expand-table-wrapper .cbb-table .td.row-height-16 {
    line-height: 16px;
  }
  .scroll-table-wrapper .cbb-table .td.row-height-15, .expand-table-wrapper .cbb-table .td.row-height-15 {
    line-height: 15px;
  }
  .scroll-table-wrapper .cbb-table .td .text-span a, .expand-table-wrapper .cbb-table .td .text-span a {
    text-decoration: none;
  }
  .scroll-table-wrapper .cbb-table .td .value-div, .expand-table-wrapper .cbb-table .td .value-div {
    font-size: 12px;
  }
  .scroll-table-wrapper .cbb-table .td .rating-div, .expand-table-wrapper .cbb-table .td .rating-div {
    font-size: 12px;
  }
  .scroll-table-wrapper .cbb-table .td .rating-div.dnq, .expand-table-wrapper .cbb-table .td .rating-div.dnq {
    font-size: 10px;
  }
  .scroll-table-wrapper .cbb-table .td.pad-left-10, .expand-table-wrapper .cbb-table .td.pad-left-10 {
    padding-left: 5px;
  }
  .pagination-row {
    margin-top: 0;
  }
  .scroll-table-wrapper .pagination-row {
    margin-top: 7px;
  }
}

