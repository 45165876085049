@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-display: swap;
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto_Slab/static/RobotoSlab-Regular.ttf");
}
@font-face {
  font-display: swap;
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Roboto_Slab/static/RobotoSlab-Bold.ttf");
}
@font-face {
  font-display: swap;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto_Mono/RobotoMono-Regular.ttf");
}
@font-face {
  font-display: swap;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Roboto_Mono/RobotoMono-Bold.ttf");
}
@font-face {
  font-display: swap;
  font-family: "Nanum Gothic Coding";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Nanum_Gothic_Coding/NanumGothicCoding-Regular.ttf");
}
@font-face {
  font-display: swap;
  font-family: "Nanum Gothic Coding";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Nanum_Gothic_Coding/NanumGothicCoding-Bold.ttf");
}
@font-face {
  font-display: swap;
  font-family: "Ubuntu Mono";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Ubuntu_Mono/UbuntuMono-Regular.ttf");
}
@font-face {
  font-display: swap;
  font-family: "Ubuntu Mono";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Ubuntu_Mono/UbuntuMono-Bold.ttf");
}
@font-face {
  font-display: swap;
  font-family: "Rift";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Rift/Rift-Regular.otf");
}
@font-face {
  font-display: swap;
  font-family: "Rift";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Rift/Rift-Medium.otf");
}
@font-face {
  font-display: swap;
  font-family: "Rift";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Rift/Rift-Demi.otf");
}
@font-face {
  font-display: swap;
  font-family: "Rift";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Rift/Rift-Bold.otf");
}

