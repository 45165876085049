html, body {
  /*height: 100%;*/
}


body {

  margin: 0;
  padding: 0;
  background: #FFFFFF;
  font-family: "Roboto", sans-serif;
  /*font-family: 'Roboto Condensed', sans-serif;*/
  letter-spacing: -0.04em;
  /*letter-spacing: normal;*/

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  /*position: relative;*/
  min-height: 99.9999vh; /* 100vh is causing, on some pages, an extra blank page on print */
  display: flex;
  flex-direction: column;
}

.ReactTable {
	/*font-family: 'B612 Mono', monospace;*/
	font-family: 'Nanum Gothic Coding', monospace;
	letter-spacing: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}