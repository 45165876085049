
@import '../node_modules/bootstrap/scss/_functions';
@import '../node_modules/bootstrap/scss/_variables';
@import '../node_modules/bootstrap/scss/mixins/_breakpoints';

// size variables
$break-mobile-large: 425px;

// color variables
$myDarkGrey: #333333;
$brandBlue: #0066CC;

// In-Table Filters
div.cbb-filter-input-wrapper {
  display: flex;
  justify-content: center;
  span.cbb-table-text-filter {
    display: flex;
    align-items: baseline;
    overflow: hidden;
    flex-grow: 1;
    margin: 0 1px;
    height: 19px;
    background: white;
    border: 1px solid #222;
    border-radius: 3px;
    input { 
      border: 0;
      padding-left: 3px;
      padding-right: 2px;
    }
  }
  span.cbb-table-filter {
    display: flex;
    align-items: center;
    background: white;
    color: #222222;
    border-radius: 3px;
    border: 1px solid #222222;
    padding: 2px;
    font-size: 0.66em;
    max-width: 35px;
    overflow: auto;
    margin-left: 1px;

    input {
      text-align: right;
      max-width: 100%;
      border: 0;
      margin: 0;
      padding: 0;
      padding-right: 3px;
      text-indent: 1px; // fixes left-side first letter cut off issue in input
      outline: none;
      -moz-appearance: textfield; // fixes (hides) ugly up,down arrows
    }

    input::placeholder {
      text-align: left;
    }
  }
  // this shows the % in the filter input
  span.show-pct:after {
    content: '%';
    margin-left: -6px;
    color: #222222;
    font-size: 0.9em;
  }
  span.show-x:after {
    content: 'x';
    margin-left: -6px;
    color: #222222;
    font-size: 0.9em;
  }
}
  

// All Table Styles
.scroll-table-wrapper, .expand-table-wrapper {
  // width: 100%; // removing from .scroll-table-wrapper, messing up pagination. is it needed? added to .expand-table-wrapper below
  position: relative; // to position in-table button in top left 
  max-width: 100%;

  // Entire Table
  .cbb-table {
    // display: inline-block;
    font-family: 'helvetica';
    letter-spacing: 0em;
    overflow: overlay;
    
    // All Rows (Both Header and Body Rows)
    // Rows (Table Body Only)
    .table-body .tr.nthRow3 {
      &:nth-child(6n), &:nth-child(6n-1), &:nth-child(6n-2) {
        [data-sticky-td] { background-color: #F2F2F2; }
        background-color: #F2F2F2;
      }
      &:nth-child(6n-3), &:nth-child(6n-4), &:nth-child(6n-5) {
        [data-sticky-td] { background-color: #FFFFFF; }
        background-color: #FFFFFF;
      }
    }

    .table-body .tr.nthRow2 {
      &:nth-child(4n), &:nth-child(4n-1) {
        [data-sticky-td] { background-color: #F2F2F2; }
        background-color: #F2F2F2;
      }
      &:nth-child(4n-2), &:nth-child(4n-3) {
        [data-sticky-td] { background-color: #FFFFFF; }
        background-color: #FFFFFF;
      }
    }

    .table-body .tr.nthRow1 {
      &:nth-child(even) { 
        [data-sticky-td] { background-color: #F2F2F2; }
        background-color: #F2F2F2;
        &.pinned-row {
          &.pin-top { border-bottom: 1px solid #999; }
          &.pin-bottom { border-top: 1px solid #999; }
          .td { background-color: #CCC; }
        }
      }
      &:nth-child(odd) { 
        [data-sticky-td] { background-color: #FFFFFF; }
        background-color: #FFFFFF;
        // Handle Total Row Right Here
        &.pinned-row {
          &.pin-top { border-bottom: 1px solid #999; }
          &.pin-bottom { border-top: 1px solid #999; }
          .td { background-color: #CCC; }
        }
      }
    }

    .table-body .tr {
      &:hover {
        background-color: rgba(0, 102, 204, 0.24);
      }
      &:last-child {
        border-bottom: 1px solid #333333;
      }
    }

    // Rows (Table Header Only)
    .table-headers .tr {
      font-weight: 700;
      background-color: #F2F2F2; // not sure about this
      &:first-child {
        border-top: 1px solid #333333;
      }
      &:last-child {
        border-bottom: 1px solid #333333;
      }
      [data-sticky-td] { 
        background-color: #F2F2F2;
      }
    }

    // Cells (Table Header)
    .th {
      font-size: 14px;
      padding: 1px;
      text-align: center;
      height: auto;
      
      &.hide-filters {
        .cbb-filter-input-wrapper {
          display: none;
        }
      }

      .sorter {
        border-radius: 5px;
        &:hover { background-color: rgba(0, 102, 204, 0.2); }
      }
      .col-sort-desc {
        border-radius: 0;
        box-shadow: inset 0 -3px 0 0 $brandBlue;
      }
      .col-sort-asc {
        border-radius: 0;
        box-shadow: inset 0 3px 0 0 $brandBlue;
      }
      &.text-left {
        text-align: left;
        padding-left: 5px;
      }
      &.child-margin-left-18 {
        div { margin-left: 18px; }
        @media print { div { margin-left: 0px; } }
      }

      &.child-margin-left-38 {
        div { margin-left: 38px; }
      }
      &.pad-left-10 { padding-left: 10px; }
      &.pad-right-10 { padding-right: 10px; }
      &.pad-left-15 { padding-left: 15px; }
      &.pad-right-15 { padding-right: 15px; }
      &.pad-left-20 { padding-left: 20px; }
      &.pad-right-20 { padding-right: 20px; }

      &.left {
        text-align: left;
        padding-left: 5px;
      }

    }

    // Cells (Table Body)
    .td {
      // width: 1%;
      font-size: 13px;
      padding: 0px;
      margin: 0px;
      height: auto;
      line-height: 28px;
      max-height: 28px;
      &:last-child { border-right: none; }
      &.pad-left-10 { padding-left: 10px; }
      &.pad-right-10 { padding-right: 10px; }
      &.pad-left-15 { padding-left: 15px; }
      &.pad-right-15 { padding-right: 15px; }

      .text-span {
        display: flex;
        justify-content: center;
        overflow: hidden;
        white-space: nowrap;
        float: left;
        color: $myDarkGrey;
        width: 100%;

        &.left {
          justify-content: flex-start;
          padding-left: 4px;
        }
        a {
          text-decoration: underline;
          color: #333;

          &:hover {
            color: $brandBlue;
          }
        }

        &.short-name {
          width: auto;
          padding-right: 4px;
          padding-left: 2px;
        }
      }
    }


    .lineup-names { 
      font-size: 12px;
      letter-spacing: -0.06em;
    }

    // Sticky Tables
    &.sticky {
      overflow: auto; // overflow: scroll;
      max-width: 100%;
      .header, .footer {

        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .table-body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
        // background: inherit;
      }

      [data-sticky-last-left-td] {
        border-right: 1px solid #DDDDDD; // box-shadow: 2px 0px 3px #ddd;
      }
    }  

    // In-Table Button
    .in-table-button {
      padding: 0;
      display: flex;
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 20; // lower than controller-navbar container (set to 25)
    }
  }
}

// Andrew's added styles
.expand-table-wrapper {
  width: 100%;
  // overflow: scroll; // NC: SEEMS LIKE WE WOULD NEED THIS, NO?

  table { 
    display: inline-flex;
    overflow: initial;
    width: 100%;
    display: table !important;

    .td.left-border {
      border-left: 1px solid #cccccc;
    }

    // .td .left {
    //   border-right: 1px solid #dddddd;
    // }

    .td.dist-zone {
      font-size: 10px !important;
    }

    .dist-zone.cbb-filter-input-wrapper.cbb-table-filter {
      width: 28px !important;
    }

    // .td.short-name {
    //   font-size: 11px !important;
    // }
  }
}

.full-table {
  display: inline-block;
}
.width-75{
  width: 75% !important;
}

.scope1 { max-width: 240px; min-width: 175px; }
.scope2 { max-width: 370px; min-width: 253px; }
.scope3 { max-width: 450px; min-width: 330px; }
.scope4 { max-width: 540px; min-width: 380px; }
// .scope5 { max-width: 522px; min-width: 367px; }
// .scope6 { max-width: 522px; min-width: 367px; }
.scope3, .scope4 {
  @media only screen and (min-width: 1200px) {
    .statValue {
      flex: 48px 0 auto;
      width: 48px;
    }
  
    .statRating {
      flex: 35px 0 auto;
      width: 35px;
    }
  }
  @media only screen and (max-width: 992px) {
    .statValue {
      flex: 40px 0 auto;
      width: 40px;
    }
  
    .statRating {
      flex: 28px 0 auto;
      width: 28px;
    }
  }
  @media only screen and (max-width: 768px) {
    .statValue {
      flex: 48px 0 auto;
      width: 48px;
    }
    .statRating {
      flex: 35px 0 auto;
      width: 35px;
    }
  }
}
.scope2TextHeader {
  display: none;
  @media print {
    display: initial;
  }
}

.sticky-left {
  .left {
    border-right: none !important;
  }
}
.sticky-left.left-last {
  border-right: 1px solid #dddddd;
}

.right-grey {
  border-right: 1px solid #dddddd;
}
.left-grey {
  border-left: 1px solid #dddddd;
}

.player-full-name-width {
  flex: 135px 0 auto;
  width: 135px;

  @media print {
    width: 100px;
  }
}

.cbb-table-resizable {
  .cbb-table {
    .table-headers {
      max-width: 100%;
      .tr { max-width: 100%; }
    }
    .table-body {
      max-width: 100%;
      .tr { max-width: 100%; }
    }
  }
}

// Pagination Row
.pagination-row {
  width: 100%;
  // margin-top: -7px;
  
  .pagination-row__panel {
    display: flex;
    float: right;
    align-items: center;
    border-bottom: 1px solid #333333;
    background: #DDDDDD;
    padding: 3px 5px;

    @media (max-width: $break-mobile-large) {
      width: 100%;
      justify-content: flex-end;
    }
  }

  select {
    margin-left: 3px;
    height: 30px;
  }

  .pagination-row__span1 { margin: 0px 4px 0 8px; }
  .pagination-row__span2 { margin: 0px 0px 0 4px; }

  .pagination-row__button { 
    color: #333333;
    background: white;
    border: 1px solid #333333;
    border-radius: 3px;
    margin-left: 2px;
  }
}
  
// Table Cells w/ Value + Rating
.value-rating-div {
  height: 100%;
}

// The Smaller # Stat Value
.value-div { 
  font-size: 13px;
  min-width: 40px;
  padding: 0px 4px 0px 0px;
  text-align: right;
  color: $myDarkGrey;
}

// The Big # Stat Rating
.rating-div {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  font-size: 14px;
  width: 100%;
  color: $myDarkGrey;
  text-align: center;
  float: right;
  &.dnq  {
    font-size: 11px;
    opacity: 0.3;
  }
}

div.table-legend {
  position: relative;
  margin-right: 3px;
  margin-top: 1px;
  height: 14px;
  width: 34px;
  border: 1px solid #222222;
  display: flex;
  align-items: center;
  justify-content: center;
  &.pctiles {
    background-image: linear-gradient(to left, #57BB8A, #FFFFFF, #E77C73);
  }
  &.ranks {
    background-image: linear-gradient(to left, #57BB8A, #fff8d7, #E77C73);
  }
  &.big {
    margin: 8px;
    margin-bottom: 0px;
    height: 30px;
    width: 250px;
  }

  span.legend-title {
    position: absolute;
    left: 254px;
    width: 100%;
    font-weight: 700;
    font-size: 1.1em;
  }
}


// Logos INSIDE of Tables
div.intable-logo {
  overflow: hidden;
  width: 24px;
  max-height: 24px;
  margin: 2px auto;
  height: 100%;

  a.logo {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

div.intable-player-image {
  position: relative;
  border: 1px solid #666;
  border-radius: 50%;
  margin: 1px auto;
  height: 26px;
  overflow: hidden;
  width: 26px;
  display: block;
  cursor: pointer;
}
// ==========


// =====================
// PRINT-ONLY STYLES
// =====================
@media print {
  @page { 
      size: auto;
      margin-right: 2.5mm;
  } 

  // Reduce table row height (1px smaller font, 4px lower line height, shrinking logo)
  div.table-legend {
    display: none;
  }
  div.intable-logo {
    width: 17px;
    max-height: 17px;
    margin: 1px auto 0px auto;
  }
  div.intable-player-image {
    width: 18px;
    max-height: 18px;
  }
  div.cbb-filter-input-wrapper { display: none; } // Hide some table stuff
  .pagination-row .pagination-row__panel { display: none; }

  // CBB Tables For Print
  .scroll-table-wrapper, .expand-table-wrapper {
    width: 100% !important;
    overflow: initial !important;
    padding-right: 15px; // creates border with right-side of page!! bump this up to 15!!!
    margin-right: 40px !important;
    display: table !important;
    transform: scaleX(0.96); // thought we got rid of this? (this helps certain tables fit better in print, keep for now still)
    transform-origin: 0 0;
    
    .cbb-table {
      display: table !important;
      overflow: initial !important;

      letter-spacing: 0.04em; // xScale to shrink, then letter-space to re-widen letters? fancy... 
      .table-body .tr { border-bottom: 1px solid #555555; }
      
      .th {
        padding: 1px;
        line-height: 1.2;
        font-size: 12px;
        &.pad-left-10 { padding-left: 5px; }
      }
      .td {
        font-size: 11px; // default 13px;
        // using printRowHeight to set classNames that change print heights of <td> elements
        line-height: 20px; // default 28px;
        &.row-height-20 { line-height: 20px; }
        &.row-height-19 { line-height: 19px; }
        &.row-height-18 { line-height: 18px; }
        &.row-height-17 { line-height: 17px; }
        &.row-height-16 { line-height: 16px; }
        &.row-height-15 { line-height: 15px; }

        .text-span a { text-decoration: none; } // remove underlines
        .value-div { font-size: 12px } // vertical compact
        .rating-div {
          font-size: 12px;
          &.dnq  { font-size: 10px; }
        } // vertical compact
        &.pad-left-10 { padding-left: 5px; }
      }
    }
  }
  
  .pagination-row {
    margin-top: 0;
  }
  .scroll-table-wrapper .pagination-row {
    margin-top: 7px;
  }
}